@import "@odx/icons/core/core-icons.css";
@import "@odx/icons/safety/safety-icons.css";
@import "@odx/ui/core-theme.css";

odx-logo {
  cursor: pointer;
}

body {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.hidden {
  display: none;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.space-between {
  justify-content: space-between;
}

.flex-column {
  flex-direction: column;
}

.overflow-hidden {
  overflow: hidden;
}

.flex-items-center {
  align-items: center;
}

app-project-products .odx-list-item__content {
  width: 100%;
  margin-right: 24px;
}

.scroll {
  overflow-y: scroll;
  overflow-x: hidden;
}

.navinta-table {
  width: 100%;
  display: table;
  table-layout: fixed;
  border-collapse: separate;

  thead {
    background-color: var(--odx-c-background-content);
    height: 48px;
    position: sticky;
    top: 0;
  }

  thead td {
    border-bottom: var(--odx-v-outline-width-bold) solid var(--gray-300);
  }

  tbody {
    height: 100%;
  }

  tr {
    height: 48px;
    cursor: pointer;
  }

  tr:hover {
    background-color: var(--odx-input-control-background-color-hover);
  }

  tr.selected {
    background-color: var(--odx-c-focus);
  }

  tr.selected:hover {
    background-color: var(--odx-c-selection);
  }

  td {
    width: 1fr;
    padding: 5px;
    vertical-align: middle;
    border-bottom: var(--odx-v-outline-width) solid var(--gray-200);
  }
}

.flex-0 {
  flex: 0;
}

.background-transparent {
  background-color: transparent;
}

.background-transparent:hover {
  background-color: transparent;
}

:root .textarea-large {
  height: 156px;
}
